.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

.result-logo {
    inherits: inherit;
    width: 80px;
    height: 72px;
}
.search-home{
  width: 100%;
  max-width: none;
}

.btn-group3 {
    position: relative;
    padding: 13px;
    width: 32rem;
    transition: all 1s;
    border-radius: 30px;
    border-top: none;
    border-right: none;
    border-bottom: none;
    border-left: 1px solid #ccc;
    @media (max-width: 576px) {
        width: 100%;
        border-radius: 3px;
        margin: 0 0 10px;
        padding: 13px;
    }
}

.searchinpt {
   width: 100%;
   flex: 1 0 auto;
   max-width: 665px;
   font-size: 1.2rem;
    font-weight: 500;
}

.searchinpt1 {
   width: 100%;
   flex: 1 0 auto;
   min-width: 560px;
   font-size: 1.0rem;
   font-weight: 460;
   margin-left: 4rem;
   background: whitesmoke;
}

.btnremove1 {
    position: relative;
    left: 3px;  
    top: -45px;
}

.btnremove2 {
  position: absolute;
    top: 16px;
    right: 25.1rem;
   /* 
    position: relative;
    top: 3px;
    left: -2.2rem;
    margin-left: -32rem; */
}

#search-result {text-align: right; margin-top: 4rem}

.results-details{}
.results-title {

}

.results-description {}
